<template>
  <div
    class="form-builder__input"
    :class="schema.styleClasses || ''"
    :disabled="schema.disabled ? true : undefined"
  >
    <div :class="`${focusClass} rounded bg-animated`">
      <div
        v-if="schema.toolbar"
        :class="schema.toolbarStyleClasses || `row items-center q-pa-sm`"
      >
        <div class="text-caption">
          {{ schema.label + (schema.required ? ' *' : '') }}
        </div>

        <q-space />

        <editor-actions
          v-if="!schema.toolbarPosition || schema.toolbarPosition === 'top'"
          :editor="editor"
          :schema="schema"
        />
      </div>

      <div class="q-pa-sm q-mx-sm q-mb-sm text-dark border rounded">
        <div
          ref="container"
          class="ProseMirror"
          :class="isFocused || $q.dark.isActive ? 'text-white' : ''"
          :disabled="schema.disabled ? true : undefined"
        />
      </div>

      <div
        v-if="schema.toolbar && schema.toolbarPosition === 'bottom'"
        :class="schema.toolbarStyleClasses || `row items-center q-px-sm q-py-xs border-top`"
      >
        <editor-actions
          :editor="editor"
          :schema="schema"
        />
      </div>
    </div>

    <div
      v-if="schema.hint"
      style="font-size: 12px; padding: 8px 12px 0; line-height: 1;"
      :style="$q.dark.isActive ? 'color: rgba(255, 255, 255, 0.7);' : 'color: rgba(0, 0, 0, 0.54);'"
    >
      {{ schema.hint }}
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { Editor } from './../../editor'

export default {
  components: {
    EditorActions: defineAsyncComponent(() => import('./EditorActions.vue'))
  },
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      editor: null,
      isFocused: false
    }
  },
  computed: {
    focusClass () {
      return this.isFocused
        ? 'bg-teal text-white'
        : this.$q.dark.isActive
          ? 'bg-editor'
          : 'bg-grey-3 text-grey-8'
    }
  },
  mounted() {
    this.editor = Editor.create({
      parent: this.$refs.container,
      content: `${this.schema.value} &nbsp;`,
      focus: 'container',
      onFocus: () => {
        if (this.schema.disabled) {
          this.$refs.container.blur()
          return
        }

        this.handleFocus()
      },
      onBlur: () => {
        this.handleFocusOut()
      },
      onUpdate: (html) => {
        typeof this.schema.onChange === 'function' && this.schema.onChange(html, this.schema.value)
      }
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  methods: {
    getButtons (buttons) {
      return buttons.filter(x => typeof x.if !== 'boolean' || x.if)
    },
    handleFocus (e) {
      this.isFocused = true
      if (this.schema.onFocus) {
        this.schema.onFocus(e)
      }
    },
    handleFocusOut (e) {
      this.isFocused = false
      if (this.schema.onFocusOut) {
        this.schema.onFocusOut(e)
      }
    }
  }
}
</script>

<style>
.bg-editor {
  background: #2D2D2D;
}
</style>
